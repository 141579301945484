@font-face {
  font-family: "PT-Root-UI";
  src : url("../fonts/PT-Root-UI_Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "PT-Root-UI";
  src : url("../fonts/PT-Root-UI_Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "PT-Root-UI";
  src : url("../fonts/PT-Root-UI_Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "PT-Root-UI";
  src : url("../fonts/PT-Root-UI_Bold.ttf");
  font-weight: 700;
}