@import "fonts";
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "PT-Root-UI";
  font-weight: 400;
  font-size: 20px;
  &.upscroll {
    padding-top: 76px;
    header {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 5;
      transition: ease-in-out 0.2s;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
    }
  }
}
.container {
  max-width: 1400px;
}
* {
  box-sizing: border-box;
}
header {
  background: #fff;
  transition: ease-in-out 0.2s;
  top: -76px;
  .header-flex {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    .logo {

    }
    .top-menu-block {
      display: flex;
    }
    nav {
      display: flex;
      align-items: center;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        li {
          a {
            color: #000;
            text-decoration: none;
            padding: 13px 10px 13px 26px;
            margin-right: 40px;
          }
        }
      }

    }
    .header-button {
      display: inline-block;
      border: 1px solid #685DE7;
      color: #685DE7;
      padding: 12px 16px;
      text-decoration: none;
    }
  }
}
.light-bg {
  background: #fff;
}
.dark-bg {
  background: #f5f5f5;
}
#main-block {
  background: #f5f5f5;
  height: 800px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  canvas {
    position: absolute;
    z-index: 0;
  }
  .main-title {
    font-weight: 700;
    font-size: 80px;
    position: relative;
    padding: 0 120px 30px;
    &:before {
      width: 107px;
      height: 79px;
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      top: 10px;
      background: url("../img/Vector-l.svg") center center no-repeat;
    }
    &:after {
      width: 107px;
      height: 79px;
      content: '';
      display: block;
      position: absolute;
      right: 0px;
      top: 10px;
      background: url("../img/Vector-r.svg") center center no-repeat;
    }
  }
  .main-sub-title {
    font-size: 24px;
    padding-bottom: 40px;
  }
  .main-button {
    display: inline-block;
    background: #685DE7;
    color: #fff;
    padding: 20px 24px;
    text-decoration: none;
    position: relative;
    z-index: 2;
  }
}
.title {
  font-weight: 500;
  font-size: 24px;
  color: #2D2D7E;
  margin-bottom: 60px;
}
#about {
  padding: 120px 0;
  .vesta-text {
    font-weight: 500;
    font-size: 36px;
  }
  p {
    margin-top: 0;
  }
}
section {
  padding: 120px 0;
}
#advantages {
  padding-bottom: 120px;
  .utp {
    background: #2D2D7E;
    padding: 40px;
    color: #fff;
    font-size: 20px;
    min-height: 355px;
    margin-bottom: 30px;
      &.light {
        background: #685DE7;
      }
    p {
      margin: 0;
    }
    .utp-title {
      font-weight: 700;
      font-size: 24px;
      margin: 10px 0 20px;
    }
  }
}
.nav-tabs {
  list-style: none;
  padding: 0 30px 0 0;
  margin: 0;
  .nav-item {
    border-left: 3px solid #685DE7;
    margin-bottom: 40px;
    padding: 20px 0 20px 10px;
    min-height: 100px;
    display: flex;
    align-items: center;
    &.active, &:hover {
      background: #E6E4FE;
    }
    .nav-link {
      color: #000000;
      text-decoration: none;
      font-weight: 500;
      font-size: 32px;

    }
  }
}
.tab-content {
  .tab-pane {
    display: none;
    &.active {
      display: block;
    }
    p {
      margin-top: 0;
    }
    ul {
      list-style: none;
      margin: 30px 0 50px 0;
      padding: 0;
      li {
        background: url("../img/arrow_vesta_li.svg") left center no-repeat;
        padding-left: 35px;
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 20px;
      }
    }
    .dev-direction {
      background: #fff;
      margin-bottom: 40px;
      padding: 20px;
    }
      .dev-direction-title {
        background-position: left center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        height: 64px;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 25px;
        img {
          margin-right: 20px;
        }
        span {
          font-weight: 400;
        }
      }
  }
}
.step {
  border-bottom: 3px solid #685DE7;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  padding: 40px;
  p {
    margin: 0;
  }
  span {
    font-weight: 500;
    font-size: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      width: 28px;
      height: 40px;
      background: url("../img/digit-arrow-left.svg") center center no-repeat;
      display: block;
      margin-right: 30px;
    }
    &:after {
      content: '';
      width: 28px;
      height: 40px;
      background: url("../img/digit-arrow-right.svg") center center no-repeat;
      display: block;
      margin-left: 30px;
    }
  }
}
#callback-block {
  background: url("../img/callback_bg.webp") center center no-repeat;
  color: #fff;
  padding: 90px 0;
  .callback-title {
    font-weight: 700;
    font-size: 80px;
  }
}
#callbackForm {
  input[type=text] {
    width: 100%;
    color: #fff;
    background: none;
    border: none;
    border-bottom: 2px solid #FFFFFF;
    line-height: 30px;
    margin-bottom: 40px;
    padding-left: 10px;
    outline: none;
    &::-webkit-input-placeholder {color:#fff;}
    &::-moz-placeholder          {color:#fff;}/* Firefox 19+ */
    &:-moz-placeholder           {color:#fff;}/* Firefox 18- */
    &:-ms-input-placeholder      {color:#fff;}
    &:-webkit-autofill {
      transition: all 5000s ease-in-out;
    }
  }
  input[type=checkbox] {
    width: 1px;
    height: 1px;
    opacity: 0;
    margin: 0;
    padding: 0;
    &+label {
      font-weight: 400;
      font-size: 14px;
      position: relative;
      padding-left: 40px;
      display: block;
      cursor: pointer;
      margin-bottom: 40px;
      &:before {
        content: '';
        width: 25px;
        height: 24px;
        display: inline-block;
        background: url("../img/checkbox.svg") center center no-repeat;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
    &:checked {
      &+label {
        &:before {
          background: url("../img/checkbox-active.svg") center center no-repeat;
        }
      }
    }
  }
  input[type=submit] {
    background: #685DE7;
    border: none;
    padding: 20px 24px;
    color: #fff;
    font-weight: 300;
    font-size: 20px;
    cursor: pointer;
  }

}
footer {
  padding: 60px 0;
  font-weight: 400;
  font-size: 14px;
  .cookie-text {
    margin-top: 50px;
  }
  .footer-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 14px 0;
      a {
        color: #000;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .footer-tel {
    color: #685DE7;
    font-weight: 500;
    font-size: 36px;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 30px;
  }
  .footer-mail {
    font-weight: 400;
    font-size: 24px;
    text-decoration: none;
    color:#000;
    display: inline-block;
  }
}
.mob-menu-btn {
  display: none;
  width: 50px;
  height: 50px;
  background: url("../img/icon-menu.svg") center center no-repeat;
  background-size: 40px;
}

@media (max-width: 1200px) {
  body {
    font-size: 18px;
  }
  header {
    .header-flex {
      nav {
        ul {
          li {
            a {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  #main-block {
    height: 500px;

    .main-title {
      font-size: 60px;
    }

    .main-sub-title {
      font-size: 20px;
    }

    .main-button {
      padding: 15px 20px;
    }
  }
  #about {
    padding: 70px 0;

    .vesta-text {
      font-size: 26px;
    }
  }
  section {
    padding: 70px 0;
  }
  #advantages .utp {
    min-height: 410px;
  }
  .step {
    padding: 40px 20px;
  }
  #callback-block {
    .callback-title {
      font-size: 64px;
    }

    background-size: cover;

    input[type=text] {
      margin-bottom: 25px
    }
  }
  footer {
    .footer-tel {
      font-size: 30px;
    }

    .footer-mail {
      font-size: 20px;
    }

    .footer-menu li {
      padding: 10px 0;
    }

    .cookie-text {
      margin-top: 30px;
    }
  }
  #advantages .utp {
    padding: 30px;
  }
  .nav-tabs .nav-item {
    min-height: 75px;
    padding: 15px 0 15px 10px;
    .nav-link {
      font-size: 20px;
    }
  }
}
@media (max-width: 1024px) {
  #main-block {
    background: url("../img/main_bg.webp") center center no-repeat;
    background-size: cover;
  }
  header .header-flex nav ul li a {
    padding: 13px 10px 13px 10px
  }
}
@media (max-width: 991px) {

  #advantages .utp {
    min-height: unset;
  }
  #advantages {
    padding-bottom: 50px;
  }
  footer .footer-tel {
    font-size: 30px;
    width: 100%;
  }
  #main-block .main-title:after, #main-block .main-title:before {
    background-size: contain;
    width: 70px;
  }
  .mob-menu-btn {
    display: block;
    position: relative;
    z-index: 20;
    &.opened {
      background: url("../img/icon-menu-opened.svg") center center no-repeat;
      background-size: 45px;
      position: fixed;
      top: 12px;
      right: 12px;
    }
  }
  header .header-flex .top-menu-block {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    background: #fff;
    z-index: 10;
    left: -120vw;
    top: 0;
    transition: ease-in-out 0.2s;
    padding: 60px 40px;
    &.opened {
      left: 0;
      transition: ease-in-out 0.2s;
    }
  }
  header .header-flex nav ul {
    flex-direction: column;
  }
  header .header-flex nav ul li a {
    font-size: 26px;
    line-height: 60px;
  }
  header .header-flex .header-button {
    color: #fff;
    padding: 25px 16px;
    text-align: center;
    font-size: 24px;
    background: #685de7;
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  #main-block .main-title {
    font-size: 30px;
    padding: 30px;
  }
  #main-block .main-title:after, #main-block .main-title:before {
    width: 30px;
  }
  .step {
    padding: 30px 0px;
    min-height: 205px;
    font-size: 16px;
    span {
      font-size: 70px;
    }
  }
  .step span:before, .step span:after {
    width: 20px;
    background-size: contain;
    margin-right: 20px;
  }
  .step span:after {
    margin-right: 0px;
    margin-left: 20px;
  }
}